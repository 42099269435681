import { render, staticRenderFns } from "./SearchOtherTags.vue?vue&type=template&id=213f3b09&scoped=true&"
import script from "./SearchOtherTags.vue?vue&type=script&lang=ts&"
export * from "./SearchOtherTags.vue?vue&type=script&lang=ts&"
import style0 from "./SearchOtherTags.vue?vue&type=style&index=0&id=213f3b09&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "213f3b09",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VAutocomplete,VCard})
