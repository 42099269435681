
































import { Vue, Component, Prop, Watch } from "vue-property-decorator"
import SearchTagChip, {
  SearchTagState,
} from "@/components/search/SearchTagChip.vue"
import { Tag, Hit, tags } from "~/config/algolia"
import { SelectTagsModel } from "@/components/search/SelectTags.vue"

@Component({
  components: {
    SearchTagChip,
  },
})
export default class SearchOtherTags extends Vue {
  items: Hit<Tag>[] = []
  selection: number[] = []
  search = ""
  @Prop({ required: true }) value!: SelectTagsModel

  @Watch("search")
  async onSearch(value = "") {
    if (this.selection.length == 0) {
      for (let [tagId, weight] of Object.entries({ ...this.value })) {
        if (weight != 0) {
          const response = await tags.search(tagId, {
            filters: `priority = 2`,
          })
          this.items = response.hits as Hit<Tag>[]
          this.selection.push(parseInt(tagId))
        }
      }
    }
    const response = await tags.search(value, { filters: `priority = 2` })
    this.items = response.hits as Hit<Tag>[]
  }

  updateValue(ids: number[]) {
    for (let id of ids) {
      if (!this.selection.includes(id)) Vue.set(this.value, id, 1)
    }
    this.selection.length = 0
    this.selection.push(...ids)
  }

  changeItemState(id: number) {
    let newState = this.value[id] + 1
    if (newState == SearchTagState.length) newState = 1
    Vue.set(this.value, id, newState)
  }

  deleteItem({ id }: Hit<Tag>) {
    const index = this.selection.indexOf(id)
    this.selection.splice(index, 1)
    Vue.set(this.value, id, 0)
  }
}
